<template>
  <v-container id="footer" fluid class="darkBackground d-print-none">
    <v-container class="pb-0">
      <v-row cols="6" class="mt-3 pb-4">
        <v-col class="d-flex align-start justify-space-around w-100 pb-0">
          <div>
            <div>
              <img width="200" class="mb-2 mt-2" src="https://atom-magic.com/shared/images/logoLight.svg" title="Atom Magic Logo" />
            </div>
            <div class="sermoNobilis sermoNobilisLarge gradientTextLight mb-2">
              THEURGIA ATOMI
            </div>
            <h6 class="gradientTextLight">
              THEURGIA ATOMI
            </h6>
          </div>
        </v-col>
        <v-col class="marginAuto d-flex align-start justify-space-around w-100 mb-5">
          <div>
            <ul id="footer-places" class="h-100">
              <li><a href="https://atom-magic.com/codex/Getting_Started" title="Getting Started">Getting Started</a></li>
              <li><a href="https://atom-magic.com/tools" title="Atom Magic Tools">Tools</a></li>
              <li><a href="https://store.atom-magic.com/" title="Store">Store</a></li>
              <li id="footer-places-contact"><a href="/omnia/Atom_Magic_Omnia:Contact" title="Atom Magic Omnia:Contact">Contact</a></li>
              <li id="footer-places-privacy"><a href="/omnia/Atom_Magic_Omnia:Privacy_policy" title="Atom Magic Omnia:Privacy policy">Privacy policy</a></li>
              <li id="footer-places-disclaimer"><a href="/omnia/Atom_Magic_Omnia:General_disclaimer" title="Atom Magic Omnia:General disclaimer">Disclaimers</a></li>
            </ul>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mb-12">
          <p class="sermoNobilis text-center gradientTextLight mb-4" lang="la">
            SŌLITŪDINEM FACIUNT PĀCEM APPELLANT
          </p>
          <p class="text-caption font-weight-light gradientTextLight text-center">
            © Adam J. Butler 2022. All rights reserved.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
</script>

<style>
</style>