<template>
	<v-app-bar class="darkBackground d-print-none" theme="dark">
		<v-app-bar-title class="pl-2 pr-2" tag="a" href="https://atom-magic.com">
			<img class="logo" src="https://atom-magic.com/shared/images/logoLight.svg" />
		</v-app-bar-title>
		<template v-slot:append>
		  <v-app-bar-nav-icon class="mobileMenuIcon" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
		</template>
		<div class="pl-1 navLinks">
			<a href="https://atom-magic.com/codex/Home" title="Atom Magic Codex">Codex</a>
			<a href="https://atom-magic.com/codex/Getting_Started" title="Getting Started with Atom Magic">Getting Started</a>
			<a href="https://atom-magic.com/tools" title="Atom Magic Tools">Tools</a>
			<a href="https://store.atom-magic.com" title="Atom Magic Online Store">Store</a>
		</div>
	</v-app-bar>
	<v-navigation-drawer
		v-model="drawer"
		floating
		temporary
		location="right"
		height="auto"
		theme="dark"
	  >
		<v-list
		>
			<v-list-item><a href="https://atom-magic.com/codex/Home" title="The Atom Magic Codex">Codex</a></v-list-item>
			<v-list-item><a href="https://atom-magic.com/codex/Getting_Started" title="Getting Started with Atom Magic">Getting Started</a></v-list-item>
			<v-list-item><a href="https://atom-magic.com/tools" title="Atom Magic Tools">Tools</a></v-list-item>
			<v-list-item><a href="https://store.atom-magic.com" title="Atom Magic Online Store">Store</a></v-list-item>
		</v-list>
	  </v-navigation-drawer>
</template>

<script>
	export default {
		name: 'NavBar',
		data () {
			return {
				settings: false,
				drawer: false,
			}
		},
		computed: {
			advancedMode: {
				get () {
					return this.$store.state.settings.advancedMode
				},
				set (value) {
					this.$store.commit('updateAdvancedMode', value)
				}
			},
			hideInstructions: {
				get () {
					return this.$store.state.settings.hideInstructions
				},
				set (value) {
					this.$store.commit('updateHideInstructions', value)
				}
			},
		},
	}
</script>

<style scoped>
	.logo {
		width: 150px;
		color: #FFFFFB;
		margin-left: 8px;
	}
	.backLink {
		color: inherit;
		background: none;
		margin-right:0;
		padding:0;
	}
	.explain {
		margin-left: 40px;
	}
	.v-app-bar {
		color: #FFFFFB;
	}
	.v-app-bar a {
		color: #FFFFFB;
		background: none;
		font-weight:400;
		letter-spacing: normal;
		margin-bottom: 5px;
		padding: 8px;
		padding-right:20px;
	}
	.v-toolbar-title {
		flex: none;
	}
	:deep(.v-toolbar-title__placeholder) {
		display: flex;
		margin-right: 16px;
	}
	.v-app-bar-buttons {
		flex: 1 1;
		text-align: right;
	}
	.mobileMenuIcon {
		color: var(--ivory-black);
		background: var(--ivory);
		border-radius: 0.3em;
		font-size: 1.5em;
		height: auto;
		margin-left: 1em;
		margin-right: 1em;
		margin-inline-end: 0px !important;
	}
</style>