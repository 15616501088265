<template>
  <v-app class="gradientBackground">
    <NavBar />
    <v-main>
      <div class="h-100 d-flex flex-column">
        <v-container class="h-100" fluid>
          <v-row class="h-100">
            <v-col cols="12" class="justify-center">
              <div class="d-flex justify-space-around h-100">
                <div class="justify-space-around align-self-center">
                  <blockquote class="blockquote text-right">
                    <div class="sermoNobilis smaller">Sumus atque estis et perĪbimus ac perĪbitis.</div>
                    <div class="blockquote-footer sermoNobilis smaller">
                      <span>CANTOS</span>,&nbsp;
                      <cite title="Source Title">
                        <span lang="la">DE NATURA CARDINALUM</span>
                      </cite>
                    </div>
                  </blockquote>
                  <blockquote class="blockquote text-right">
                    <div>We are as you are, and we will be undone as you are undone.</div>
                    <div class="blockquote-footer">
                      <span class="trajan">Polyphony</span>,&nbsp;
                      <cite title="Source Title">
                        <span class="trajan" lang="la">De Natura Cardinalum</span>
                      </cite>
                    </div>
                  </blockquote>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="d-flex h-100 align-end">
          <Footer />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Footer from './components/Footer.vue';
import NavBar from './components/NavBar.vue';
export default {
  name: 'App',

  components: {
    Footer,
    NavBar,
  },

  data: () => ({
    //
  }),
}
</script>

<style>
  body, main {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  }
  .v-btn,
  .v-card-actions .v-btn {
    padding: 0px 16px;
  }
  .v-btn:hover,
  .v-card-actions .v-btn:hover {
    padding: 0px 16px;
  }
  .v-navigation-drawer {
    height: auto !important;
    border-radius: 0 0 .5em .5em;
  }
  .v-navigation-drawer__content {
    border-radius: 0 0 .5em .5em;
  }
  .v-navigation-drawer__scrim {
    opacity: 0 !important;
  }
  .v-table > .v-table__wrapper > table > tbody > tr > td, .v-table > .v-table__wrapper > table > tbody > tr > th, .v-table > .v-table__wrapper > table > thead > tr > td, .v-table > .v-table__wrapper > table > thead > tr > th, .v-table > .v-table__wrapper > table > tfoot > tr > td, .v-table > .v-table__wrapper > table > tfoot > tr > th {
    padding: 0 0;
  }
  .v-list-item--density-compact:not(.v-list-item--nav).v-list-item--one-line {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
  .v-navigation-drawer {
    margin-left: 16px !important;
    margin-right: 16px !important;
    width: calc((100% - 32px) - 0px) !important;
    border-radius: 0.5em;
  }
  .v-navigation-drawer, .v-navigation-drawer .v-theme--dark {
    background: var(--ivory-black) !important;
  }
  .logo {
    max-width: 300px;
    height: auto;
  }
  .sermoNobilis {
    font-family: "lapideum-2022-v1", sans-serif;
    letter-spacing: 0.72em !important;
    font-size: 0.6em !important;
    font-weight: 400 !important;
    vertical-align: middle;
    line-height: 2.6em;
  }
  .sermoRomani {
    font-family: "trajan", sans-serif;
    text-transform: lowercase;
  }
  .bigger {
    font-size: 0.8em !important;
    font-weight: 500 !important;
  }
  .gradientBackground {
    background-image: linear-gradient(
      305deg,
      hsl(34deg 87% 79%) 0%,
      hsl(37deg 81% 79%) 10%,
      hsl(41deg 75% 79%) 20%,
      hsl(45deg 69% 79%) 30%,
      hsl(49deg 62% 79%) 40%,
      hsl(55deg 56% 80%) 50%,
      hsl(54deg 59% 83%) 60%,
      hsl(52deg 63% 86%) 70%,
      hsl(51deg 69% 89%) 80%,
      hsl(50deg 79% 93%) 90%,
      hsl(49deg 100% 96%) 100%
    );
  }
  .backgroundTwo {
    background-image: linear-gradient(
      130deg,
      hsl(60deg 2% 90%) 0%,
      hsl(58deg 12% 89%) 21%,
      hsl(56deg 21% 89%) 30%,
      hsl(54deg 30% 88%) 39%,
      hsl(53deg 38% 87%) 46%,
      hsl(51deg 45% 86%) 54%,
      hsl(50deg 52% 85%) 61%,
      hsl(49deg 59% 84%) 69%,
      hsl(48deg 66% 84%) 79%,
      hsl(46deg 72% 83%) 100%
    );
    background: #FFFDF3;
  }
  .darkBackground {
    background: var(--ivory-black) !important;
    color: rgb(255, 255, 251);
  }
  .footer {
    min-height:400px;
  }
  .blockquote-footer {
    font-size:80%;
    display:block;
  }
  .blockquote-footer:before {
    content: "— ";
  }
  .small {
    font-size: .8em !important;
  }
  .smaller {
    font-size: .4em !important;
  }
  @media (min-width: 960px) {
    .v-app-bar-nav-icon {
      display:none;
    }
  }
  @media (max-width: 960px) {
    .v-row {
      display: block;
    }
    .v-app-bar-buttons, .navLinks {
      display: none;
    }
    #footer > .v-container > .v-row {
      display: block;
    }
    #footer-places {
      margin-top: 1em !important;
    }
    #footer-places li {
      margin-bottom: 1em;
    }
    #footer a {
      font-size: 1.25em !important;
    }
  }
</style>